@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&display=swap');
html {
  width: 100%!important;
}
.video-players-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  font-family: Lato;
  margin-top: 50px;
  * {
    margin: 0;
  }
  .filter-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .search-input {
      width: 70%;
      padding: 20px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 20px;
      font-size: 16px;
      background-image: url("../img/search.svg");
      background-repeat: no-repeat;
      background-position: 97% 50%;
      background-size: 25px;
      height: 0.2em;
    }
    .search-input::placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
    .sort-select {
      padding-top: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  .video-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80%;
    .video-item {
      display: flex;
      border-radius: 5px;
      background-color: #fff; /* Add a background color to make the shadow visible */
      width: 100%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      .video-player-wrapper {
        padding: 2%!important;
        width: 35%;
        .react-player {
          width: 90%;
          border-radius: 5px;
        }
      }
      .video-details-container {
        padding: 2%!important;
        width: 65%;
        .video-title {
          font-size: 40px;
        }
        .upload-date {
          font-style: italic;
          font-size: 15px;
        }
        .description-heading {
          margin-top: 10px;
          margin-bottom: 5px;
          font-size: 25px;
        }
        .description {
          max-width: 880px;
          width: 100%;
          font-size: 20px;
        }
      }
    }
  }
  .more-back {
    display: flex;
    gap: 40px;
    .button-container {
      display: flex;
      justify-content: center;
      .moreButton,
      .backButton {
        margin: 10px 0;
        padding: 15px 40px;
        font-size: 1em;
        border: none;
        border-radius: 5px;
        color: #DA291C;
        background: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      .moreButton:hover,
      .backButton:hover {
        color: #8e0900;
      }
    }
  }
}
@media screen and (min-width:210px) and (max-width:1024px) {
  .video-players-container {
    margin-top: 35px;
    .video-container {
      .video-item {
        flex-direction: column;
        .video-player-wrapper {
          display: flex!important;
          width: 96%;
          .react-player {
            height: 21vh!important;
          }
        }
        .video-details-container {
          width: 100%;
          .video-title {
            font-size: 24px;
            width: 96%;
          }
          .upload-date {
            font-size: 12px;
            width: 96%;
          }
          .description-heading {
            margin-top: 7px;
            margin-bottom: 3.5px;
            font-size: 18px;
            width: 96%;
          }
          .description {
            width: 96%;
            font-size: 17px;
          }
        }
      }
    }
  }
}
/* @media screen and (max-width: 1025px) {
  .sortOption{
    font-size: 14px!important;
  }
  
}
@media screen and (max-width: 900px) {
  .sortOption{
    font-size: 12px!important;
  }
    
} */
@media screen and (min-width: 350px) and (max-width: 500px) {
  .video-title {
    font-size: 15px!important;
  }
  .upload-date {
    margin-top: 0.7em;
  }
  .description-heading{
    font-size: 13px!important;
  }
  .description
  {
    font-size: 12px!important;
    span{
      font-size: 12px!important;
    }
  }
}
@media screen and (min-width: 501px) and (max-width: 700px) {
  .video-title {
    font-size: 15px!important;
  }
  .upload-date {
    margin-top: 0.7em;
  }
  .description-heading{
    font-size: 13px!important;
  }
  .description
  {
    font-size: 12px!important;
    span{
      font-size: 12px!important;
    }
  }
}
@media screen and (min-width: 701px) and (max-width: 900px) {
  .video-title {
    font-size: 16px!important;
  }
  .upload-date {
    margin-top: 0.7em;
  }
  .description-heading{
    font-size: 14px!important;
  }
  .description
  {
    font-size: 14px!important;
    span{
      font-size: 14px!important;
    }
  }
}
@media screen and (min-width: 901px) and (max-width: 1100px) {
  .video-title {
    font-size: 17px!important;
  }
  .upload-date {
    margin-top: 0.7em;
  }
  .description-heading{
    font-size: 15px!important;
  }
  .description
  {
    font-size: 15px!important;
    span{
      font-size: 15px!important;
    }
  }
}
@media screen and (min-width: 1101px) {
  .video-title {
    font-size: 20px!important;
  }
  .upload-date {
    margin-top: 0.7em;
  }
  .description-heading{
    font-size: 19px!important;
  }
  .description
  {
    font-size: 20px!important;
    span{
      font-size: 20px!important;
    }
  }
}