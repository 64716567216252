/* Modal.css */
/* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
} 
.modal-content
{
  position: relative;
  z-index: 100;
  background-color: white;
  padding: 10px;
  width: 80vw;
  display: flex;
  flex-direction: row;
  height: 50vh;
  gap: 2em;
  border-radius: 10px;
}
.image-container img{
  width: 35vw;
  height: 50vh;
}
.image-title{
  font-size: 40px;
  margin-bottom: 0.5em;
}
.image-description{
  font-size: 20px;
}
.Hellobro{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.publication-date
{
  font-size: 20px;
}
@media screen and (max-width: 1000px) {
  .image-title{
    font-size: 30px !important;
  }
  .image-description{
    font-size: 15px !important;
  }
  .publication-date
  {
    font-size: 15px;
  }
}

@media screen and (max-width: 700px) {
  .image-title{
    font-size: 20px !important;
  }
  .image-description{
    font-size: 10px !important;
  }
  .publication-date
  {
    font-size: 10px;
  }
  .image-container img{
    width: 35vw;
    height: 30vh;
  }
  .modal-content
  {
    height: 30vh;
    gap: 1em;
  }
}
@media screen and (max-width: 400px) {
  .image-title{
    font-size: 15px !important;
  }
  .image-description{
    font-size: 10px !important;
  }
  .publication-date
  {
    font-size: 10px;
  }
  .image-container img{
    width: 35vw;
    height: 30vh;
  }
  .modal-content
  {
    height: 30vh;
    gap: 1em;
  }
}