.hey-hey-hey
{
    position: relative!important;
    z-index: 1!important;
}
.container-feedback
{
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: row;
    .container-feedback-about{
        width: 50%;
        background-color: #DA291C;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        align-items: center;
        padding-bottom: 1.5em;
        .rule-rpp {
            display: flex;
            gap: 20px;
            margin-bottom: 20px;
            .privacy, .term {
                color: #fff;
            }
        }
        .container-feedback-icon
        {
            svg {
                width: 30px;
                height: 30px;
                padding: 10px;
                cursor: pointer;
            }
            svg:nth-child(1) {
                g{
                    &:hover {
                        fill: rgba(27,116,228,1.0);
                    }
                }
            }
            svg:nth-child(2) {
                g{
                    &:hover {
                        fill: #000;
                    }
                }
            }
            svg:nth-child(3) {
                g{
                    &:hover {
                        fill: #0088cc;
                    }
                }
            }
            svg:nth-child(4) {
                g{
                    &:hover {
                        fill: #000000;
                    }
                }
            }
        }
        .feedback-title{
            font-size: 20px;
            justify-content: start;
            display: flex;
            margin: 0;
            margin-top: 2em;
            font-weight: bold;
        }
    }
    .submit-btn {
        background-color: #DA291C;
        color: #fff;
        border: none;
        border-radius: 20px;
        padding: 0.5em 3em;
        font-size: 20px;
        cursor: pointer;
        margin-top: 1em;
        &:hover {
            background-color: #ea1000;
        }
    }
}
.describe-aboutme
{
    text-align: center;
    max-width: 80%;
    width: 80%;
}


.container-feedback-report{
    width: 50%;
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
    gap: 6px;
    .container-feedback-input-identify
    {
        display: flex;
        flex-direction: row;
        justify-content: center;

        p{
            padding: 0 1em;
        }
    }
    #free-solo-demo
    {
        width: 18em;
    }
    #free-solo-2-demo{
        width: 18em;
    }
    #free-solo-demoddd
    {
        width: 39em;
        height: 2.5em;
    }
   
}

.feedback-title
{
    font-size: 20px;
    justify-content: start;
    display: flex;
    margin: 0;
    margin-bottom: 0.5em;
    font-weight: bold;
}
@media screen and (max-width: 1800px) {
    #free-solo-demo
    {
        width: 14em!important;
    }
    #free-solo-2-demo{
        width: 14em!important;
    }
    #free-solo-demoddd
    {
        width: 32em!important;
        height: 2.5em;
    }
}

@media screen and (max-width: 1350px) {
    #free-solo-demo
    {
        width: 10em!important;
    }
    #free-solo-2-demo{
        width: 10em!important;
    }
    #free-solo-demoddd
    {
        width: 24.5em!important;
        height: 2.5em;
    }
}
@media screen and (max-width: 900px) {
    .container-feedback{
        display: flex;
        flex-direction: column-reverse;
    }
    .container-feedback-about{
        width: 100% !important;
    }
    .container-feedback-report{
        margin-top: 2em;
        width: 100% !important;
    }
    #free-solo-demo
    {
        width: 18em!important;
    }
    #free-solo-2-demo{
        width: 18em!important;
    }
    #free-solo-demoddd
    {
        width: 40.2em!important;
        height: 2.5em;
    }
    @media screen and (max-width: 800px){
        #free-solo-demo
        {
            width: 12em!important;
        }
        #free-solo-2-demo{
            width: 12em!important;
        }
        #free-solo-demoddd
        {
            width: 28.2em!important;
            height: 2.5em;
        }
    }
    @media screen and (max-width: 550px){
        #free-solo-demo
        {
            width: 8em!important;
        }
        #free-solo-2-demo{
            width: 8em!important;
        }
        #free-solo-demoddd
        {
            width: 20.2em!important;
            height: 2.5em;
        }
        .container-feedback-icon{
        img{
            width: 4em!important;
        }}
    }
    @media screen and (max-width: 400px){
        #free-solo-demo
        {
            width: 6em!important;
        }
        #free-solo-2-demo{
            width: 6em!important;
        }
        #free-solo-demoddd
        {
            width: 16.2em!important;
            height: 2em;
        }
        .container-feedback-icon{
        img{
            width: 3em!important;
        }}
    }
}