/* ImageGallery.css */
.video-players-container
{
  margin-top: 35px;
}
.image-gallery-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  * {
    margin: 0;
  }
  .search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    .search-input {
      width: 70%;
      padding: 20px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 20px;
      font-size: 16px;
      background-image: url("../img/search.svg");
      background-repeat: no-repeat;
      background-position: 97% 50%;
      background-size: 25px;
      height: 0.2em;
    }
    .search-input::placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }
  .image-grid {
    margin-top: 50px;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 20px;
    .okkbrook {
      .image {
        border: 1px solid #ccc;
        padding: 10px;
      }
    }
  }
  .more-button{
    margin: 20px 0;
    font-size: 14px;
    border: none;
    background-color: #fff;
    color: #DA291C;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .more-button:hover {
    color: #8e0900;
  }
}

.image
{
  display: flex;
  flex-direction: column-reverse;
  width: 22vw;
  height: 25vh;
  justify-content: space-between;
  box-shadow: 0 2px 4px #0000001a;
  margin-top: 1em;
  border: 1px solid #00000015;

  img{
    width: 100%;
    height: 20vh;
  }
  h2{
    font-size: 15px;
    padding: 5px;
  }
}
.okkbrook
{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width:1575px) and (max-width:1825px) {
  .image-gallery-container {
    .image-grid {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      .okkbrook {
        .image {
          width: 30vw;
        }
      
      }
    }
  }
}

@media screen and (min-width:969px) and (max-width:1574px) {
  .image-gallery-container {
    .image-grid {
      .okkbrook {
        .image {
          width: 30vw;
        }
      }
    }
  }
}

@media screen and (max-width: 968px) {
  .image-gallery-container {
    .image-grid {
      .okkbrook {
        .image {
          width: 60vw;
        }
      }
    }
  }
}

@media screen and (max-width: 1124px) {
  .image-gallery-container {
    .image-grid {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }
}