.video-players-container
{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  font-family: Lato;
  margin-top: 50px;
}

.view-pdf-container {
  /* padding: 20px; */
  font-family: Lato;
  display: flex;
  flex-direction: column;
  align-items: center;
  .input-container {
    /* margin-top: 40px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .search-input {
      width: 70%;
      padding: 20px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 20px;
      font-size: 16px;
      background-image: url("../img/search.svg");
      background-repeat: no-repeat;
      background-position: 97% 50%;
      background-size: 25px;
      height: 0.2em;
    }
    .search-input::placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }
  .pdf-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    .pdf-item {
      display: flex!important;
      flex-direction: row!important;
      width: 70vw;
      gap: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 15px;
      .pdf-title {
        font-size: 20px;
        margin-top: 10px;
      }
      img {
        width: 300px;
        height: 350px;
      }
      .pdf-description {
        margin-top: 10px;
      }
      .pdf-date {
        margin-top: 10px;
        font-style: italic;
        font-size: 16px;
      }
    }
  }
}

.pdf-link{
  text-decoration: none;
  background-color: #DA291C;
  color: #fff;
  width: 200px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  &:hover {
    background-color: #ea1000;
  }
}
.nest-pdf-link{
  margin-top: 1em;
  width: 100%;
}

@media screen and (min-width:699px) and (max-width:1079px) {
  .view-pdf-container {
    .pdf-list {
      .pdf-item {
        img {
          width: 230px;
          height: 360px;
        }
      }
    }
  }
}

@media screen and (min-width: 300px) and (max-width:698px) {
  .view-pdf-container {
    .pdf-list {
      .pdf-item {
        img {
          width: 200px;
          height: 310px;
        }
      }
    }
  }
}
@media screen and (max-width:949px) {
  .view-pdf-container {
    .pdf-list {
      .pdf-item {
        .pdf-content {
          .nest-pdf-link {
            .pdf-link {
              width: 130px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 200px) and (max-width:588px) {
  .view-pdf-container {
    .pdf-list {
      .pdf-item {
        .pdf-content {
          .nest-pdf-link {
            .pdf-link {
              width: 100px;
            }
          }
        }
        img {
          width: 120px;
          height: 170px;
        }
      }
    }
  }
}