/* player.css */  
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&display=swap');
/* .css-8n6ywc-MuiTypography-root {
  font-family: Lato!important;
}
.video-players-container{
  margin-top: 35px;
}
.button-a
{
  border: none;
}
.pdf-item-more{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.audio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  * {
    margin: 0;
  }
  .sort-container {
    margin-bottom: 20px;
  }
  .search-input {
    width: 70%;
    padding: 20px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    background-image: url("../img/search.svg");
    background-repeat: no-repeat;
    background-position: 97% 50%;
    background-size: 25px;
    height: 0.2em;
  }
  .search-input::placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  .audio-card-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 70vw;
    .css-1dz4c52-MuiPaper-root-MuiCard-root {
      display: flex;
      .make-ctimg {
        display: flex;
        align-items: center;
      }
    }
    .audio-image {
      padding: 10px;
      width: 24vw!important;
      height: 30vh!important;
    }
    .audio-detail {
      height: 90%;
      .audio-title {
        font-weight: bold;
        font-size: 40px;
        margin-top: 10px;
      }
      .audio-date {
        font-size: 15px;
        font-style: italic;
      }
      .audio-description {
        color: black;
        font-size: 20px;
        font-weight: 400;
        width: 100%;
        max-width: 43vw;
      }
    }
    .audio-display {
      display: flex;
      align-items: center;
      margin-bottom: 10px!important;
      div {
        audio {
          width: 35vw;
        }
      }
    }
    .css-17cm5o9-MuiStack-root {
      align-items: start;
    }
    .play-button {
      display: none;
      justify-content: center;
      height: 60px;
      width: 60px;
      background-color: #007bff;
      .css-i4bv87-MuiSvgIcon-root  {
        font-size: 40px;
        color: white;
      }
    }
  }
}   
.audio-card-container {
  & .css-1dz4c52-MuiPaper-root-MuiCard-root {
    & .css-17cm5o9-MuiStack-root {
      & .audio-display {
        & div {
          audio{
            display: block!important;
          }
        }
      }
    }
  }
} */
@media screen and (min-width:210px) and (max-width:1023px) {
  /* .audio-card-container {
    gap: 20px;
    width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    .css-1dz4c52-MuiPaper-root-MuiCard-root {
      display: flex;
      width: 100%!important;
      flex-direction: column;
      align-items: center;
      .audio-image {
        padding: 0!important;
        width: 71.2vw!important;
        max-width: 100vw!important;
        height: 23.5vh!important;
      }
      .css-17cm5o9-MuiStack-root {
        display: flex!important;
        flex-direction: column!important;
        align-items: center!important;
        margin-left: -3%!important;
        .audio-detail {
          .audio-title {
            margin-top: 0!important;
            display: flex;
            justify-content: center!important;
            font-size: 24px;
          }
          .audio-description {
            text-align: justify;
            font-size: 14px;
            max-width: 65vw!important;
          }
        }
        .audio-display {
          display: flex;
          div {
            audio {
              width: 55vw;
            }
          }
        }
        .play-button {
          height: 40px;
          width: 40px;
          .css-i4bv87-MuiSvgIcon-root  {
            font-size: 30px;
          }
        }
      }
    }
  } */
} 
.audio-load-more{
  color: #DA291C;
  margin: 10px 0;
}
.audio-load-more:hover{
  color: #8e0900;
}
@media screen and (min-width: 350px) and (max-width: 500px) {
  /* .audio-title
  {
    font-size: 15px!important;
  }
  .audio-description{
    font-size: 12px!important;
  } */
}
@media screen and (min-width: 501px) and (max-width: 700px) {
  /* .audio-title
  {
    font-size: 15px!important;
  }
  .audio-description{
    font-size: 12px!important;
  } */
}
@media screen and (min-width: 701px) and (max-width: 900px) {
  /* .audio-title
  {
    font-size: 16px!important;
  }
  .audio-description{
    font-size: 14px!important;
  } */
}
@media screen and (min-width: 901px) and (max-width: 1100px) {
  /* .audio-title
  {
    font-size: 17px!important;
  }
  .audio-description{
    font-size: 15px!important;
  } */
}
@media screen and (min-width: 1101px) {
  /* .audio-title
  {
    font-size: 20px!important;
  }
  .audio-description{
    font-size: 20px!important;
  } */
}
@media screen and (max-width: 5990px) {
  /* .css-1dz4c52-MuiPaper-root-MuiCard-root{
    padding: 0!important;
  } */
}

/* 
@media screen and (min-width: 350px) and (max-width: 500px) {
 
}
@media screen and (min-width: 501px) and (max-width: 700px) {
  
}
@media screen and (min-width: 701px) and (max-width: 900px) {

}
@media screen and (min-width: 901px) and (max-width: 1100px) {
  
}
@media screen and (min-width: 1101px) {
 
} */