.admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  font-family: Lato;
  margin: 50px 0;
  * {
    margin: 0;
  }
  .head {
    font-size: 24px;
    font-weight: bold;
  }
  .user-container {
    width: 60%;
    margin-top: -40px;
    margin-bottom: 40px;
    p, button {
      font-size: 15px;
    }
  }
  
  .admin-form {
    width: 60%;
    display: flex;
    flex-direction: column;
    .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      label {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .selection {
        border: 1px solid #000000;
        border-radius: 5px;
        width: 20%;
        padding: 10px;
      }
      input {
        padding: 10px;
        border: 1px solid #000000;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
      }
      .MuiPaper-root {
        height: 40vh;
      }
    }
    .btn-submit {
      background-color: #007bff; /* Green */
      color: white;
      padding: 12px 20px;
      border: none;
      font-size: 15px;
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;
    }
    .btn-submit:hover {
      background-color: #0056b3;
    }
  }
  .success-message,
  .error-message {
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
  }
  .success-message {
    color: green;
  }
  .error-message {
    color: red;
  }
}  
@media screen and (min-width:375px) and (max-width:767px) {
  .admin-container {
    .head {
      font-size: 20px;
    }
    .user-container {
      width: 80%;
      p, button {
        font-size: 13px;
      }
    }
    .admin-form {
      width: 80%;
      .form-group {
        .selection {
          width: 40%;
        }
        input {
          padding: 8px;
        }
      }
      .btn-submit {
        padding: 10px 15px;
        font-size: 13px;
      }
    }
  }
  
}