@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&display=swap');

.audiov2-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    * {
        margin: 0;
        padding: 0;
    }
    .sort-container {
        margin-bottom: 20px;
    }
    .search-input {
        width: 70%;
        padding: 20px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 20px;
        font-size: 16px;
        background-image: url("../img/search.svg");
        background-repeat: no-repeat;
        background-position: 97% 50%;
        background-size: 25px;
        height: 0.2em;
    }
    .search-input::placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }
    /* .loading-class {
        display: flex;
        justify-content: center;
        align-items: center;
    } */
    .audio-card-container {
        display: flex!important;
        flex-direction: column!important;
        align-items: center!important;
        gap: 20px!important;
        padding: 0 4%!important;
        .audio-card {
            width: 100%;
            display: flex;
            border: 1px solid #ccc;
            border-radius: 5px;
            .audio-image {
                padding: 10px;
                width: 320px;
                box-sizing: border-box;
                object-fit: cover;
            }
            .audio-detail {
                height: 90%;
                padding: 0 10px;
                .audio-title {
                    font-weight: bold;
                    font-size: 25px;
                    margin-top: 10px;
                }
                .audio-date {
                    font-size: 15px;
                    font-style: italic;
                }
                .audio-description {
                    color: black;
                    font-size: 20px;
                    font-weight: 400;
                    height: 190px;
                    width: 100%;
                    max-width: 1000px;
                    padding-right: 10px;
                }
            }
            .audio-display {
                display: flex!important;
                align-items: center!important;
                margin-bottom: 10px!important;
                div {
                    audio {
                        width: 50vw!important;
                    }
                }
            }
            .play-button {
                display: none!important;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .audiov2-container {
        .audio-card-container {
            flex-direction: column;
            gap: 20px; /* Ensure gap is maintained */
            padding: 0 5%;
            .audio-card {
                flex-direction: column;
                .audio-display {
                    align-items: center;
                    div {
                        audio {
                            width: 80vw;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .audiov2-container {
        .audio-card-container {
            .audio-card {
                .audio-image {
                    width: 100%;
                    height: 40vh;
                }
            }
        }
    }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
    .audiov2-container {
        .audio-card-container {
            .audio-card {
                .audio-image {
                    width: 100%;
                    height: 20vh;
                }
            }
        }
    }
}

@media screen and (max-width: 567px) {
    .audiov2-container {
        .audio-card-container {
            .audio-card {
                .audio-image {
                    width: 100%;
                    height: 15vh;
                }
            }
        }
    }
}