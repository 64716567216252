@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&display=swap');
.carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Lato;

    .indicators {
        width: 80%;
        margin-top: 3em !important;
        background-color: #D9D9D9;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-weight: bold;
        #slide {
            cursor: pointer;
            width: 20vw;
            padding: 20px 50px;
            border-radius: 20px;
            .icon {
                font-size: 30px;
                display: flex;
                justify-content: center;
            }
            span {
                display: flex;
                justify-content: center;
                font-size: 20px;
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .indicators {
        .indicator{
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                display: none !important;
            }
        }
    }
}
@media screen and (min-width:210px) and (max-width:1200px) {
    .carousel-container {
        .indicators {
            #slide {
                padding: 5px 20px;
                .icon {
                    font-size: 12px;
                }
                span {
                    font-size: 8px;
                }
            }
        }
    }
}