@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

.header-container{
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin: 0;
    align-items: center;
    *{
        margin: 0;
        font-family: Oswald;
    }
}
.header-container-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    h6{
        float: left;
        font-size: 20px;
        font-weight: 500;
    }
    p{
        font-size: 15px;
        float: left;
    }
}

@media screen and (max-width: 650px) {
    .header-container{
        .logo-header-file
        {
            height: 35px;
            width: 75px;
        }
    }
    .header-container-text{
        h6{
            float: left;
            font-size: 12px;
        }
        p{
            font-size: 9px;
            float: left;
        }
    }
}


.logo-header-file
{
    width: 90px;
    height: 50px;
}