.edit-delete-files-container {
  width: 100vw;
  font-family: Lato;
  margin: 50px 0;
  .edit-delete-files-heading {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .select-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    label {
      width: 70%;
      display: flex;
      justify-content: start;
      font-weight: bold;
    }
    .select-file-dropdown {
      width: 70%;
      padding: 8px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
  .file-details {
    width: 100%;
    display: flex!important;
    flex-direction: column!important;
    align-items: center;
    margin-top: 20px;
    .file-details-heading {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .file-field {
      width: 70%;
      margin-bottom: 10px;
      .edit-label {
        width: 10%;
        font-weight: bold;
      }
      .edit-input {
        width: 100%;
        padding: 8px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    } 
    .file-actions {
      margin-top: 20px;
    }
  }
  .edit-button,
  .delete-button,
  .save-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .edit-button {
    background-color: #007bff;
    color: #fff;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: #fff;
  }
  
  .save-button {
    background-color: #28a745;
    color: #fff;
  }
  
  .success-message,
  .error-message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
  }
  
  .success-message {
    background-color: #28a745;
    color: #fff;
  }
  
  .error-message {
    background-color: #dc3545;
    color: #fff;
  }
}    